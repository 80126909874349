import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1)
  };

  return (
    <button onClick={handleBack} style={styles.button} aria-label="Go Back">
      ← {/* This is the back arrow symbol */}
    </button>
  );
};

const styles = {
  button: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontSize: '24px', // Adjust size as needed
    color: '#553455',
    padding: '10px',
    outline: 'none',
  },
};

export default BackButton;
