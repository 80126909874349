import axios from "axios";
import { config } from "../../utils/axiosconfig";
const base_url=process.env.REACT_APP_BASE_URL 

// Function to create a new product
const createProducts = async (productData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.post(`${base_url}anotherprod/create`, productData, config);
  return response.data;
};




const getProducts = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${base_url}anotherprod`, config);
  return response.data;
};
// Delete Product
const deleteProduct = async (id,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(`${base_url}anotherprod/${id}`,config);
  return response.data;
};

const productService = {
  getProducts,
  createProducts,
  deleteProduct
};

export default productService;
