import axios from "axios";
const base_url=process.env.REACT_APP_BASE_URL 

const getUsers = async () => {
  const response = await axios.get(`${base_url}user/all-users`);

  return response.data;
};

// Get all orders for a specific user
const getUserOrders = async (userId) => {
  const token = localStorage.getItem('token'); // Assuming you're storing the token in localStorage

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${base_url}mainadmin/get-userorders/${userId}`, config);

  return response.data;
};

const getSellers = async () => {
  const response = await axios.get(`${base_url}admin/all-admins`);

  return response.data;
};

// Get all orders for a specific user
const getSellerProducts = async (adminId) => {
  const token = localStorage.getItem('token'); // Assuming you're storing the token in localStorage

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${base_url}mainadmin/get-adminproducts/${adminId}`, config);

  return response.data;
};
const customerService = {
  getUsers,
  getUserOrders,
  getSellers,
  getSellerProducts
};

export default customerService;
