import React, { useState } from "react";
import axios from "axios";
import BackButton from "../components/BackButton";
import { toast } from "react-toastify";

const ProductForm = () => {
  const base_url = process.env.REACT_APP_BASE_URL;

  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(null);
  const [fabricImg, setFabricImg] = useState(null);
  const [color, setColor] = useState("");
  const [admin, setAdmin] = useState("");
  const [description, setDescription] = useState("");
  const [preview, setPreview] = useState("");
  const [preview1, setPreview1] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token"); // Assumes token is stored in localStorage

    const formData = new FormData(); // Create a FormData object to send files and data
    formData.append("category", category);
    formData.append("description", description);
    formData.append("brand", brand);
    formData.append("price", price);
    formData.append("color", color);
    formData.append("admin", admin);
    if (image) {
      formData.append("image", image); // Append the image file
    }
    if (fabricImg) {
      formData.append("fabricImg", fabricImg); // Append the fabric image file
    }

    try {
      await axios.post(`${base_url}product/add`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
        },
      });
      toast.info("Product added successfully!");
      // Reset form after successful submission
      setCategory("");
      setBrand("");
      setPrice("");
      setColor("");
      setAdmin("");
      setDescription("");
      setImage(null);
      setFabricImg(null);
      setPreview("");
      setPreview1("");
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Failed to add product");
    }
  };

  const handleFileUpload1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file); // Save the file directly
      const imageUrl = URL.createObjectURL(file); // Create a temporary URL for the file
      setPreview(imageUrl); // Set the preview to the temporary URL
    } else {
      setImage(null);
      setPreview("");
    }
  };

  const handleFileUpload2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFabricImg(file); // Save the file directly
      const imageUrl = URL.createObjectURL(file); // Create a temporary URL for the file
      setPreview1(imageUrl); // Set the preview to the temporary URL
    } else {
      setFabricImg(null);
      setPreview1("");
    }
  };

  return (
    <div>
      <h3 className="mb-4 title">
        <BackButton /> Add-Pre Product
      </h3>
      <div>
        <form onSubmit={handleSubmit} className="d-flex gap-3 flex-column">
          <div>
            <label>Category:</label>
            <input
              type="text"
              placeholder="provide category like men, women..."
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Description:</label>
            <input
              type="text"
              placeholder="provide prompt"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Price:</label>
            <input
              type="number"
              placeholder="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Brand:</label>
            <input
              type="text"
              placeholder="brand"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Color:</label>
            <input
              type="text"
              placeholder="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Admin:</label>
            <input
              type="text"
              placeholder="provide adminId"
              value={admin}
              onChange={(e) => setAdmin(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Image:</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileUpload1}
              required
            />
          </div>
          {preview && (
            <img
              src={preview}
              alt="Preview"
              style={{ width: "200px", height: "auto" }}
            />
          )}
          <div>
            <label>Fabric Image:</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileUpload2}
              required
            />
          </div>
          {preview1 && (
            <img
              src={preview1}
              alt="Preview"
              style={{ width: "200px", height: "auto" }}
            />
          )}
          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit"
          >
            Add Product
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProductForm;
