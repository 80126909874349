import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams} from "react-router-dom";
import { getUserOrders } from "../features/cutomers/customerSlice"; // Import the getUserOrders action from customerSlice
import { Spin, Alert } from "antd";
import BackButton from "../components/BackButton";
import Modal from "../components/Modal";

const OrderList = () => {
  const dispatch = useDispatch();
  const { userId } = useParams(); // Get the userId from the route params

  const [modalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const openModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentImage("");
  };
  // Get userOrders and status from Redux state
  const { userOrders, isLoading, isError, message } = useSelector(
    (state) => state.customer // Assuming you named the slice 'customer' in your store setup
  );

  // Fetch the user's orders on component mount and when userId changes
  useEffect(() => {
    if (userId) {
      dispatch(getUserOrders(userId)); // Dispatch getUserOrders with userId
    }
  }, [dispatch, userId]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Function to handle image click for modal
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  if (isLoading) return <Spin />; // Show a loading spinner while data is fetching

  if (isError) {
    // Display error message if there's an issue fetching the orders
    const errorMessage =
      typeof message === "object" ? JSON.stringify(message) : message;
    return <Alert message="Error" description={errorMessage} type="error" />;
  }

  return (
    <div className="order-details-container">
      <h2>
        <BackButton /> Orders Of User
      </h2>
      {userOrders && userOrders.length > 0 ? (
        userOrders.flatMap((order, orderIndex) => (
          <div key={order._id} className="order-item">
            <h4>Order #{order._id}</h4>
            <p>Placed on: {new Date(order.createdAt).toLocaleDateString()}</p>
            <h6>UserId #{order.userId}</h6>
            <div className="order-items">
              {order.items.map((item, itemIndex) => (
                <div key={itemIndex} className="order-items">
                  <p className="serial-number">
                    No: {orderIndex + itemIndex + 1}
                  </p>{" "}
                  {/* Adjusting serial number */}
                  <img
                    src={item.imageUrl}
                    alt={item.name}
                    className="order-item-image"
                    onClick={() => openModal(item.imageUrl)}
                  />
                  <div className="order-item-details">
                    <p>
                      <strong>{item.name}</strong>
                    </p>
                    <p className="price">Price: ₹{item.price}</p>
                    <p>Quantity: {item.quantity}</p>
                    <strong>Size (inches):</strong>
                    {item.size && Object.keys(item.size).length > 0 ? (
                      Object.keys(item.size).map((label) => (
                        <div key={label}>
                          <span>{label}: </span>
                          <input
                            type="number"
                            value={item.size[label]}
                            readOnly // You can add this if you don't want to allow changes
                          />
                        </div>
                      ))
                    ) : (
                      <p>No size data available</p> // You can customize this message
                    )}{" "}
                  </div>
                  <div>
                    <p>Fabric of the Product</p>
                    <h6>AdminId #{item.adminId}</h6>
                    <img
                      src={item.fabricImg}
                      alt={item.name}
                      className="order-item-image"
                      onClick={() => handleImageClick(item.fabricImg)}
                    />
                  </div>
                </div>
              ))}
            </div>
            <p className="price">Total Price: ₹{order.totalPrice}</p>
            <p
              className={`status ${
                order.status === "Cancelled" ? "cancelled" : ""
              }`}
            >
              Status: {order.status}
            </p>
            <div className="shipping-info">
              <h5 className="shipping">Shipping Information</h5>
              <p>
                <strong>Name:</strong>
                {order.shippingInfo.name}
              </p>
              <p>
                <strong>Address:</strong> {order.shippingInfo.locality},{" "}
                {order.shippingInfo.address}, {order.shippingInfo.city},
                {order.shippingInfo.state}
              </p>
              <p>
                <strong>Pincode:</strong> {order.shippingInfo.pincode}
              </p>
              <p>
                <strong>Phone:</strong> {order.shippingInfo.phone}
              </p>
              <h5 className="payment">Payment Info</h5>
              <p>
                <strong>RazorpayOrderId:</strong>{" "}
                {order.paymentInfo.razorpayOrderId}
              </p>
              <p>
                <strong>RazorpayPaymentId:</strong>{" "}
                {order.paymentInfo.razorpayPaymentId}
              </p>
            </div>
          </div>
        ))
      ) : (
        <p>No orders found.</p>
      )}

      <Modal isOpen={modalOpen} onClose={closeModal} imageUrl={currentImage} />

      {/* Modal for displaying the clicked fabric image */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <p>THIS IS THE FABRIC OF PRODUCT</p>
            <img src={selectedImage} alt="Popup" className="modal-image" />
            <button className="modal-close" onClick={handleCloseModal}>
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderList;
