import React, { useState } from "react";
import { AiOutlineShoppingCart, AiOutlineUser } from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { FaClipboardList, FaFirstOrderAlt, FaSellcast } from "react-icons/fa";
import { LiaSignOutAltSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../features/auth/authSlice";
import { getToken, getUsername } from "../utils/axiosconfig";
import { MdOutlinePlaylistAddCheckCircle } from "react-icons/md";

// const { Header, Sider, Content } = Layout;
const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      // await dispatch(logout()).unwrap();
      localStorage.removeItem('token');
      navigate("/"); // Navigate to the login page on successful logout
      window.location.reload();
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const token = getToken();
  const username = getUsername();

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  const handleItemClick = () => setSidebarOpen(false); // Close the sidebar when an item is clicked

  return (
    <>
      <header className="header">
        <div className="left-section">
          <div className="menu-icon" onClick={toggleSidebar}>
            &#9776;
          </div>{" "}
          {/* Menu Icon */}
        </div>
        <div className="center-section " style={{ fontSize: "15px" }}>
          <Link to="/admin">
            {" "}
            <span className="logo fav text-black">Flexura.ai</span>
          </Link>{" "}
          {/* Logo */}
        </div>
        <div className="right-section">
          {token ? (
            <span> Admin!</span>
          ) : (
            <Link to="/">
              <span className="icon user-icon m-4 ">🔑 Login</span>
            </Link>
          )}
        </div>
        {/* Sidebar */}
        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
          <div className="close-icon" onClick={toggleSidebar}>
            ×
          </div>
          <div className="login-section">
            <span className="login-icon">🙂</span>
            {token ? (
              <span>"Admin"!</span>
            ) : (
              <span className="login-text">LOG IN</span>
            )}
          </div>
          <ul className="menu-list">
            <Link to="/admin/customers" onClick={handleItemClick}>
              {" "}
              <li>
                <AiOutlineUser /> CUSTOMERS
              </li>
            </Link>
            <Link to="/admin/sellers" onClick={handleItemClick}>
              {" "}
              <li>
                <FaSellcast /> SELLERS
              </li>
            </Link>
            <Link to="/admin/list-product" onClick={handleItemClick}>
              {" "}
              <li>
                <MdOutlinePlaylistAddCheckCircle /> SELLER-PRODUCT-LIST
              </li>
            </Link>
            <Link to="/admin/orders" onClick={handleItemClick}>
              {" "}
              <li>
                <FaFirstOrderAlt /> ORDERS
              </li>
            </Link>
            <Link to="/admin/enquiries" onClick={handleItemClick}>
              {" "}
              <li>
                <FaClipboardList /> ENQUIRIES
              </li>
            </Link>
            <Link to="/admin/productform" onClick={handleItemClick}>
              {" "}
              <li>
                <AiOutlineShoppingCart /> PRE-GEN-PRODUCTS
              </li>
            </Link>
            <Link to="/admin/pre-product-list" onClick={handleItemClick}>
              {" "}
              <li>
                <MdOutlinePlaylistAddCheckCircle /> PRE-GEN-PRODUCT-LIST
              </li>
            </Link>
            <li onClick={handleLogout}>
              <LiaSignOutAltSolid /> SIGNOUT
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};
export default Header;
