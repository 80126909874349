import React from "react";
import { Link } from "react-router-dom";
import { FaLinkedin,FaInstagram,FaYoutube ,FaTelegram} from "react-icons/fa";
const Footer = () => {
  return (
    <>
  
      <footer className="py-4">
        <div className="container-xxl">
          <div className="row">
            <div className="col-4">
              <h4 className="text-black mb-4">Contact Us</h4>
              <div>
                <address className="text-black fs-5">
                  Hno.D-64 Laxmi-Nagar, <br /> East-Delhi ,Delhi <br />
                  Pincode:110092
                </address>
                <a
                  href="tel:+91 9876543210"
                  className="mt-3 d-block mb-1 "
                >
                  +91 9876543210
                </a>
                <a
                  href="mailto:jaiswal@gmail.com"
                  className="mt-2 d-block mb-0 "
                >
                  jaiswal@gmail.com
                </a>
                <div className="social-icons d-flex align-items-center gap-30 mt-4">
                  <a className="text-black" href="">
                    <FaLinkedin  className="fs-5"/>
                    </a>
                  <a  className="text-black" href="">
                   <FaInstagram className="fs-5" />
                   </a>
                  <a  className="text-black" href="">
                   <FaYoutube className="fs-5" />
                    </a>
                  <a  className="text-black" href="">
                   <FaTelegram className="fs-5" />
                    </a>
                </div>
              </div>
            </div>
            <div className="col-3 " >
            
            </div>
            <div className="col-3">
              <h4 className="text-black mb-4">Account</h4>
              <div className="footer-links d-flex flex-column ">
                <Link to='/' className="text-black py-2 mb-1">About-Us</Link>
                <Link to='/' className="text-black py-2 mb-1">FAQ</Link>
                <Link to='/' className="text-black py-2 mb-1">Contact</Link>
                <Link to='/' className="text-black py-2 mb-1">Blogs</Link>
              </div>
            </div>
          
          </div>
        </div>
      </footer>
      <footer className="py-6">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <p className="text-center mb-0 text-black">
                &copy;{new Date().getFullYear()}:Powered by Nano-ai
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>




  
  );
};

export default Footer;
