// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: Roboto, sans-serif;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.ant-layout {
  min-height: 100vh;
}
.ant-layout-sider {
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
}
.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}
.sm-logo {
  display: none;
}

.ant-layout-sider-collapsed .sm-logo {
  display: block;
}
.ant-layout-sider-collapsed .lg-logo {
  display: none;
}
.logo {
  background-color: #ffd333;
  margin: 0;
  height: 64px;
}
/* main {
  height: 85vh;
  overflow-y: scroll;
  background-color: transparent !important;
} */
/* main::-webkit-scrollbar {
  width: 0px;
} */
/* .ant-menu-title-content {
  font-size: 16px;
} */
/* .ant-layout-header h5 {
  line-height: 20px;
  font-size: 14px;
} */
/* .ant-layout-header p {
  line-height: 20px;
  font-size: 13px;
} */
/* .ant-layout-header div .badge {
  top: 16px;
  right: -6px;
} */
/* .error {
  color: red;
  font-size: 12px;
} */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,oCAAoC;AACtC;AACA;EACE,iBAAiB;AACnB;AACA;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,2BAA2B;EAC3B,uBAAuB;AACzB;AACA;EACE,yBAAyB;EACzB,0BAA0B;EAC1B,0BAA0B;EAC1B,sBAAsB;AACxB;AACA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;AACA;EACE,aAAa;AACf;AACA;EACE,yBAAyB;EACzB,SAAS;EACT,YAAY;AACd;AACA;;;;GAIG;AACH;;GAEG;AACH;;GAEG;AACH;;;GAGG;AACH;;;GAGG;AACH;;;GAGG;AACH;;;GAGG","sourcesContent":["body {\n  font-family: Roboto, sans-serif;\n}\n\n.trigger {\n  padding: 0 24px;\n  font-size: 18px;\n  line-height: 64px;\n  cursor: pointer;\n  transition: color 0.3s;\n}\n\n.trigger:hover {\n  color: #1890ff;\n}\n\n.logo {\n  height: 32px;\n  margin: 16px;\n  background: rgba(255, 255, 255, 0.3);\n}\n.ant-layout {\n  min-height: 100vh;\n}\n.ant-layout-sider {\n  flex: 0 0 250px !important;\n  max-width: 250px !important;\n  min-width: 250px !important;\n  width: 250px !important;\n}\n.ant-layout-sider-collapsed {\n  flex: 0 0 80px !important;\n  max-width: 80px !important;\n  min-width: 80px !important;\n  width: 80px !important;\n}\n.sm-logo {\n  display: none;\n}\n\n.ant-layout-sider-collapsed .sm-logo {\n  display: block;\n}\n.ant-layout-sider-collapsed .lg-logo {\n  display: none;\n}\n.logo {\n  background-color: #ffd333;\n  margin: 0;\n  height: 64px;\n}\n/* main {\n  height: 85vh;\n  overflow-y: scroll;\n  background-color: transparent !important;\n} */\n/* main::-webkit-scrollbar {\n  width: 0px;\n} */\n/* .ant-menu-title-content {\n  font-size: 16px;\n} */\n/* .ant-layout-header h5 {\n  line-height: 20px;\n  font-size: 14px;\n} */\n/* .ant-layout-header p {\n  line-height: 20px;\n  font-size: 13px;\n} */\n/* .ant-layout-header div .badge {\n  top: 16px;\n  right: -6px;\n} */\n/* .error {\n  color: red;\n  font-size: 12px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
