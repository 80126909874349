import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import axios from "axios";
import {
  getProducts,
  deleteProduct,
  reset,
} from "../features/product/productSlice";
import BackButton from "../components/BackButton";

const Productlist = () => {
const base_url=process.env.REACT_APP_BASE_URL 

  const [productItem, setProductItem] = useState([]);

  useEffect(() => {
    const fetchItem = async () => {
      const token = localStorage.getItem("token"); // Retrieve token from local storage

      try {
        const response = await axios.get(`${base_url}anotherprod/all`, {
          headers: {
            Authorization: `Bearer ${token}`, // Attach the token to the request header
          },
        });
        setProductItem(response.data);
      } catch (error) {
        console.error("Failed to fetch cart items:", error);
      }
    };

    fetchItem();
  }, []);

  const columns = [
    {
      title: "SNo",
      dataIndex: "key",
      width: 50, // Set a fixed width for small devices
    },
    {
      title: "AdminId",
      dataIndex: "adminid",
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (text, record) => (
        <img src={record.image} alt={record.brand} className="product-image" />
      ),
      width: 100, // Set a fixed width for small devices
    },
    {
      title: "Brand",
      dataIndex: "brand",
      width: 150, // Set a fixed width for small devices
    },
    {
      title: "Category",
      dataIndex: "category",
      width: 150, // Set a fixed width for small devices
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 300, // Set a wider column width for descriptions
    },
    {
      title: "Color",
      dataIndex: "color",
      width: 100, // Set a fixed width for small devices
    },
    {
      title: "Price",
      dataIndex: "price",
      width: 100, // Set a fixed width for small devices
    },
  ];

  const data1 = productItem.map((product, index) => ({
    key: index + 1,
    _id: product._id,
    adminid: product.admin,
    image: product.image,
    brand: product.brand,
    category: product.category,
    description: product.description,
    color: product.color,
    price: `${product.price}`,
  }));

  return (
    <>
      <div className="product-list-container">
        <h3 className="mb-4 title">
          <BackButton /> Products
        </h3>
        <Table
          columns={columns}
          dataSource={data1}
          scroll={{ x: "100vw" }} // Allow horizontal scrolling on smaller screens
        />
      </div>
    </>
  );
};

export default Productlist;
