const getTokenFromLocalStorage = localStorage.getItem("token")
  ? (localStorage.getItem("token"))
  : null;
// utils/authUtils.js
export const getToken = () => localStorage.getItem('token');
export const getUsername = () => localStorage.getItem('firstname');
export const config = {
  headers: {
    Authorization: `Bearer ${
      getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
    }`,
    Accept: "application/json",
  },
};
