import axios from "axios";
import { config } from "../../utils/axiosconfig";
const base_url=process.env.REACT_APP_BASE_URL 
const getEnquiries = async () => {
  const response = await axios.get(`${base_url}enquiry/`);

  return response.data;
};
const deleteEnquiry = async (id) => {
  const response = await axios.delete(`${base_url}enquiry/${id}`,  {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`, // Replace with the actual token if stored elsewhere
    },
  });
  return response.data;
};
const getEnquiry = async (id) => {
  const response = await axios.get(`${base_url}enquiry/${id}`);
  return response.data;
};
const udpateEnquiry = async (enq) => {
  const response = await axios.put(
    `${base_url}enquiry/${enq.id}`,
    { status: enq.enqData },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Replace with the actual token if stored elsewhere
      },
    }
  );
  return response.data;
};
const enquiryService = {
  getEnquiries,
  deleteEnquiry,
  getEnquiry,
  udpateEnquiry,
};

export default enquiryService;
