import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSellers, getUsers } from "../features/cutomers/customerSlice";
import BackButton from "../components/BackButton";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const Sellers = () => {
const base_url=process.env.REACT_APP_BASE_URL 

  const dispatch = useDispatch();
  const [accountDetails, setAccountDetails] = useState([]);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState(null);

  // Fetch admin's account details
  const fetchAccountDetails = async (adminId) => {
    try {
      const response = await axios.get(
        `${base_url}mainadmin/adminaccdetails/${adminId}`
      );
      setAccountDetails(response.data.accountDetails || []);
      setIsAccountModalOpen(true); // Open modal after fetching account details
    } catch (error) {
      console.error("Error fetching account details:", error);
    }
  };

  useEffect(() => {
    dispatch(getSellers());
  }, []);

  // Function to handle account click
  const handleAccountClick = (adminId) => {
    setSelectedAdminId(adminId);
    fetchAccountDetails(adminId);
  };

  // Function to close the account modal
  const handleCloseAccountModal = () => {
    setIsAccountModalOpen(false);
    setAccountDetails([]); // Clear account details when closing modal
  };
  const columns = [
    {
      title: "SNo",
      dataIndex: "key",
    },
    {
      title: "AdminId",
      dataIndex: "adminid",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
    {
      title: "Account Details",
      render: (text, record) => (
        <Button
          style={{ background: "aquamarine" }}
          onClick={() => handleAccountClick(record.adminid)}
        >
          View Account Details
        </Button>
      ),
      width: 150, // Set a fixed width for small devices
    },
  ];
  const customerstate = useSelector((state) => state.customer.sellers);
  const data1 = [];
  for (let i = 0; i < customerstate.length; i++) {
    if (customerstate[i].role === "admin") {
      data1.push({
        key: i + 1,
        adminid: customerstate[i]._id,
        name: customerstate[i].firstname + " " + customerstate[i].lastname,
        email: customerstate[i].email,
        mobile: customerstate[i].mobile,
        action: (
          <Link to={`/admin/seller-products/${customerstate[i]._id}`}>
            <Button type="primary">View Products</Button>
          </Link>
        ),
      });
    }
  }

  return (
    <div className="product-list-container">
      <h3 className="mb-4 title">
        <BackButton /> Sellers
      </h3>
      <Table
        columns={columns}
        dataSource={data1}
        scroll={{ x: "100vw" }} // Allow horizontal scrolling on smaller screens
      />
      {/* Modal for displaying the account details */}
      {/* Modal for displaying the account details */}
      <Modal
        title="Account Details"
        visible={isAccountModalOpen}
        onCancel={handleCloseAccountModal}
        footer={[
          <Button key="close" onClick={handleCloseAccountModal}>
            Close
          </Button>,
        ]}
        bodyStyle={{ maxHeight: "400px", overflowY: "auto" }} // For responsiveness
      >
        {accountDetails.length > 0 ? (
          accountDetails.map((account, index) => (
            <div key={index} style={{ marginBottom: "1rem" }}>
              <p>
                <strong>Account Number:</strong> {account.accountNumber}
              </p>
              <p>
                <strong>IFSC Code:</strong> {account.ifscCode}
              </p>
              <p>
                <strong>UPI ID:</strong> {account.upiId}
              </p>
              <hr />
            </div>
          ))
        ) : (
          <p>No account details available.</p>
        )}
      </Modal>
    </div>
  );
};

export default Sellers;
