import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Modal from "../components/Modal";
import BackButton from "../components/BackButton";

const ViewOrder = () => {
const base_url=process.env.REACT_APP_BASE_URL 

  const { id } = useParams(); // Get the order ID from the URL
  const [order, setOrder] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const openModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentImage("");
  };
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const token = localStorage.getItem("token"); // Fetch token from localStorage

        const response = await axios.get(`${base_url}admin/get-orders/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setOrder(response.data);
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();
  }, [id]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Function to handle image click
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };
  if (!order) return <p>Loading...</p>;

  return (
    <div className="order-details-container">
      <h3 style={{ fontSize: "12px" }}>Order #{order._id}</h3>
      <p>
        <BackButton /> Placed on:{" "}
        {new Date(order.createdAt).toLocaleDateString()}
      </p>

      <div className="order-items">
        <h3 style={{ fontSize: "12px" }}>UserId #{order.userId}</h3>

        {order.items.map((item, index) => (
          <div key={index} className="order-item">
            <img
              src={item.imageUrl}
              alt={item.name}
              className="order-item-image"
              onClick={() => openModal(item.imageUrl)}
            />
            <div className="order-item-details">
              <p>
                <strong>Product:</strong> {item.name}
              </p>
              <p className="price">
                <strong>Price:</strong> ₹{item.price}
              </p>
              <p>
                <strong>Quantity:</strong> {item.quantity}
              </p>
              <strong>Size (inches):</strong>
              {item.size && Object.keys(item.size).length > 0 ? (
                Object.keys(item.size).map((label) => (
                  <div key={label}>
                    <span>{label}: </span>
                    <input
                      type="number"
                      value={item.size[label]}
                      readOnly // You can add this if you don't want to allow changes
                    />
                  </div>
                ))
              ) : (
                <p>No size data available</p> // You can customize this message
              )}
            </div>
            <div>
              <p>
                <strong>Fabric:</strong>THIS THE FABRIC OF THE PRODUCT
              </p>
              <h3 style={{ fontSize: "12px" }}>AdminId #{item.adminId}</h3>
              <img
                src={item.fabricImg}
                alt={item.name}
                className="order-item-image"
                // onClick={() => openModal(item.imageUrl)}
                onClick={() => handleImageClick(item.fabricImg)}
              />
            </div>
          </div>
        ))}
      </div>
      <Modal isOpen={modalOpen} onClose={closeModal} imageUrl={currentImage} />
      {isModalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <p>THIS IS THE FABRIC OF THIS PRODUCT</p>
            <img src={selectedImage} alt="Popup" className="modal-image" />
            <button className="modal-close" onClick={handleCloseModal}>
              &times;
            </button>
          </div>
        </div>
      )}
      <p className="price">
        <strong>Total Price:</strong> ₹{order.totalPrice}
      </p>
      <p
        className={`status ${order.status === "Cancelled" ? "cancelled" : ""}`}
      >
        <strong>Status:</strong> {order.status}
      </p>

      <div className="shipping-info">
        <h5 className="shipping">Shipping Information</h5>
        <p>
          <strong>Name:</strong>
          {order.shippingInfo.name}
        </p>
        <p>
          <strong>Address:</strong> {order.shippingInfo.locality},{" "}
          {order.shippingInfo.address}, {order.shippingInfo.city},
          {order.shippingInfo.state}
        </p>
        <p>
          <strong>Pincode:</strong> {order.shippingInfo.pincode}
        </p>
        <p>
          <strong>Phone:</strong> {order.shippingInfo.phone}
        </p>
      </div>
    </div>
  );
};

export default ViewOrder;
