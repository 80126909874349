import "./App.css";
import {  Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import MainLayout from "./components/MainLayout";
import Enquiries from "./pages/Enquiries";
import Orders from "./pages/Orders";
import Customers from "./pages/Customers";
import Productlist from "./pages/Productlist";
import ViewEnq from "./pages/ViewEnq";
import ViewOrder from "./pages/ViewOrder";
import ProductForm from "./pages/Formproduct";
import PreProductlist from "./pages/Pre-Productlist";
import { PrivateRoutes } from "./routing/PrivateRoute";
import OrdersList from "./pages/CustomerOrders";
import Sellers from "./pages/Sellers";
import SellerProductlist from "./pages/SellerProducts";
import useAxiosInterceptor from "./utils/axiosInstance";
function App() {

  useAxiosInterceptor();
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/admin" element={<PrivateRoutes><MainLayout /></PrivateRoutes>}>
          <Route index element={<Orders />} />
          {/* <Route index element={<Dashboard />} /> */}

          <Route path="enquiries" element={<Enquiries />} />
          <Route path="enquiries/:getEnqId" element={<ViewEnq />} />
          <Route path="orders" element={<Orders />} />
          <Route path="order/:id" element={<ViewOrder />} />
          <Route path="customers" element={<Customers />} />
          <Route path="sellers" element={<Sellers />} />
          <Route path="user-orders/:userId" element={<OrdersList />} />
          <Route path="seller-products/:adminId" element={<SellerProductlist />} />
          <Route path="list-product" element={<Productlist />} />
          <Route path="productform" element={<ProductForm />} />
          <Route path="pre-product-list" element={<PreProductlist />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
