import React, { useEffect, useState } from "react";
import { Table, Button, Spin, Alert } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  getProducts,
  deleteProduct,
  reset,
} from "../features/product/productSlice";
import BackButton from "../components/BackButton";
import { getSellerProducts } from "../features/cutomers/customerSlice";
import { useParams } from "react-router-dom";

const SellerProductlist = () => {
  const dispatch = useDispatch();
  const { adminId } = useParams(); // Get the userId from the route params

  const { sellerProducts, isLoading, isError, message } = useSelector(
    (state) => state.customer // Assuming you named the slice 'customer' in your store setup
  );

  // Fetch the user's orders on component mount and when userId changes
  useEffect(() => {
    if (adminId) {
      dispatch(getSellerProducts(adminId)); // Dispatch getUserOrders with userId
    }
  }, [dispatch, adminId]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Function to handle image click for modal
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  if (isLoading) return <Spin />; // Show a loading spinner while data is fetching

  if (isError) {
    // Display error message if there's an issue fetching the orders
    const errorMessage =
      typeof message === "object" ? JSON.stringify(message) : message;
    return <Alert message="Error" description={errorMessage} type="error" />;
  }
  const columns = [
    {
      title: "SNo",
      dataIndex: "key",
      width: 50, // Set a fixed width for small devices
    },
    {
      title: "AdminId",
      dataIndex: "adminid",
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (text, record) => (
        <img
          src={record.image}
          alt={record.brand}
          className="product-image"
          onClick={() => handleImageClick(record.image)}
        />
      ),
      width: 100, // Set a fixed width for small devices
    },
    {
      title: "Brand",
      dataIndex: "brand",
      width: 150, // Set a fixed width for small devices
    },
    {
      title: "Category",
      dataIndex: "category",
      width: 150, // Set a fixed width for small devices
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 300, // Set a wider column width for descriptions
    },
    {
      title: "Color",
      dataIndex: "color",
      width: 100, // Set a fixed width for small devices
    },
    {
      title: "Price",
      dataIndex: "price",
      width: 100, // Set a fixed width for small devices
    },
    ,
  ];

  const data1 = sellerProducts.map((product, index) => ({
    key: index + 1,
    _id: product._id,
    adminid: product.admin,
    image: product.image,
    brand: product.brand,
    category: product.category,
    description: product.description,
    color: product.color,
    price: `${product.price}`,
  }));

  return (
    <>
      <div className="product-list-container">
        <h3 className="mb-4 title">
          <BackButton /> Products
        </h3>
        <Table
          columns={columns}
          dataSource={data1}
          scroll={{ x: "100vw" }} // Allow horizontal scrolling on smaller screens
        />
        {/* Modal for displaying the clicked fabric image */}
        {isModalOpen && (
          <div className="modal-overlay" onClick={handleCloseModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <p>THIS IS THE FABRIC OF PRODUCT</p>
              <img src={selectedImage} alt="Popup" className="modal-image" />
              <button className="modal-close" onClick={handleCloseModal}>
                &times;
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SellerProductlist;
