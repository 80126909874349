import axios from "axios";
import { config } from "../../utils/axiosconfig";
const base_url=process.env.REACT_APP_BASE_URL 
const register= async (userData)=>{
  const response = await axios.post(`${base_url}mainadmin/register`, userData);
  if(response.data){
    if(response.data){
    return response.data;
}}
};
const login = async (userData) => {
  try {
    const response = await axios.post(`${base_url}mainadmin/mainadmin-login`, userData);
    if (response.data) {
      // Extract the token from the response
      const { token,firstname } = response.data;
      
      // Store only the token in localStorage
      if (token && firstname) {
        localStorage.setItem("token", token);
        localStorage.setItem("firstname", firstname);
      }
   
      return response.data;
    }
  } catch (error) {
    console.error('Error during login:', error);
    throw error; // Optionally handle the error
  }
};
// Function to handle logout
 const logout = async () => {
  try {
      const response = await axios.get(`${base_url}mainadmin/logout`,{},{ withCredentials: true });
       // Clear the token from localStorage
  localStorage.removeItem('token');
  localStorage.removeItem('firstname');
      return response;
  } catch (error) {
      throw new Error(error.response ? error.response.data.message : 'Logout failed');
  }
};



const getOrders = async () => {
  const response = await axios.get(`${base_url}user/getallorders`, config);

  return response.data;
};
const getOrder = async (id) => {
  const response = await axios.post(
    `${base_url}user/getorderbyuser/${id}`,
    "",
    config
  );

  return response.data;
};

const authService = {
  register,
  login,
  logout,
  getOrders,
  getOrder,
};

export default authService;
