// src/components/AdminLogin.js
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Icons for eye toggle

const Login = () => {
  const base_url = process.env.REACT_APP_BASE_URL;

  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") ? true : false
  );
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${base_url}mainadmin/mainadmin-login`,
        { password }
      );
      if (response.data.token) {
        // Store the token in localStorage
        localStorage.setItem("token", response.data.token);
        setLoggedIn(true);
        navigate("/admin");
        setError("");
      } else {
        setError("Invalid password");
      }
    } catch (error) {
      setError("Error  login");
    }
  };

  if (loggedIn) {
    return <div>Welcome, Admin!</div>;
  }
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div>
      <div
        className="section"
        style={{
          border: "1px solid #ddd",
          background: "rgb(212, 233, 226,0.6)",
        }}
      >
        <h1 className="fav"> Flexura.ai</h1>
      </div>
      <div className="auth-container">
        <h2>Admin Login</h2>
        <form onSubmit={handleSubmit}>
          <label>Password</label>
          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter admin password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span onClick={togglePassword} className="eye-icon">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <button className="submit-button" type="submit">
            Login
          </button>
        </form>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    </div>
  );
};

export default Login;
