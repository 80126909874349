import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const useAxiosInterceptor = () => {
  const navigate = useNavigate();

  // Intercept the response to check for token expiration
  axios.interceptors.response.use(
    (response) => {
      // If the response is successful, just return the response
      return response;
    },
    (error) => {
      // Check if the error message contains "Token Expired"
      if (error.response && error.response.data.message === "Not Authorized, Token Expired, Please Login Again") {
        // Clear the token from local storage (optional)
        localStorage.removeItem('token');
        window.location.reload(); // Reload the page

        // Navigate to the login page
        navigate('/');
      }

      // Return the error so it can be handled in the catch block of the request
      return Promise.reject(error);
    }
  );
};

export default useAxiosInterceptor;
