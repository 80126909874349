import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  getProducts,
  deleteProduct,
  reset,
} from "../features/product/productSlice";
import BackButton from "../components/BackButton";
import Modal from "../components/Modal";

const PreProductlist = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const [productItem, setProductItem] = useState([]); // Initialize as an empty array
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const openModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentImage("");
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const response = await axios.get(`${base_url}product/pre-product`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Fetched product items:", response.data); // Log the response
        if (Array.isArray(response.data)) {
          setProductItem(response.data);
        } else {
          console.error("Expected an array, but received:", response.data);
          setProductItem([]); // Set to an empty array if the structure is wrong
        }
      } catch (error) {
        console.error("Failed to fetch product items:", error);
        setProductItem([]); // Optionally set to an empty array on error
      }
    };

    fetchItem();
  }, []);

  const handleDelete = async (productId) => {
    try {
      await axios.delete(`${base_url}product/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProductItem((prevProducts) =>
        prevProducts.filter((product) => product._id !== productId)
      );
      window.location.reload();
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const columns = [
    {
      title: "SNo",
      dataIndex: "key",
      width: 50,
    },
    {
      title: "AdminId",
      dataIndex: "adminid",
      width: 50,
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (text, record) => (
        <img
          src={record.image}
          alt={record.brand}
          className="product-image"
          onClick={() => openModal(record.image)}
        />
      ),
      width: 100,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      width: 150,
    },
    {
      title: "Category",
      dataIndex: "category",
      width: 150,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 300,
    },
    {
      title: "Color",
      dataIndex: "color",
      width: 100,
    },
    {
      title: "FabricImg",
      dataIndex: "fabricimg",
      render: (text, record) => (
        <img
          src={record.fabricimg}
          alt={record.brand}
          className="product-image"
          onClick={() => handleImageClick(record.fabricimg)}
        />
      ),
      width: 100,
    },
    {
      title: "Price",
      dataIndex: "price",
      width: 100,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <Button
          type="link"
          className="delete-button"
          onClick={() => handleDelete(record._id)}
        >
          <AiFillDelete />
        </Button>
      ),
      width: 100,
    },
  ];

  const data1 = Array.isArray(productItem)
    ? productItem.map((product, index) => ({
        key: index + 1,
        _id: product._id,
        adminid: product.admin,
        image: product.image,
        brand: product.brand,
        category: product.category,
        description: product.description,
        color: product.color,
        fabricimg: product.fabricImg,
        price: `${product.price}`,
      }))
    : [];

  return (
    <>
      <div className="product-list-container">
        <h3 className="mb-4 title">
          <BackButton /> Products
        </h3>
        <Table columns={columns} dataSource={data1} scroll={{ x: "100vw" }} />
        <Modal
          isOpen={modalOpen}
          onClose={closeModal}
          imageUrl={currentImage}
        />
        {isModalOpen && (
          <div className="modal-overlay" onClick={handleCloseModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <p>THIS IS THE FABRIC OF THIS PRODUCT</p>
              <img src={selectedImage} alt="Popup" className="modal-image" />
              <button className="modal-close" onClick={handleCloseModal}>
                &times;
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PreProductlist;
